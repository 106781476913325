exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-business-setup-guide-js": () => import("./../../../src/pages/business-setup-guide.js" /* webpackChunkName: "component---src-pages-business-setup-guide-js" */),
  "component---src-pages-business-setup-kingdom-of-saudi-arabia-js": () => import("./../../../src/pages/business-setup-kingdom-of-saudi-arabia.js" /* webpackChunkName: "component---src-pages-business-setup-kingdom-of-saudi-arabia-js" */),
  "component---src-pages-business-setup-packages-js": () => import("./../../../src/pages/business-setup-packages.js" /* webpackChunkName: "component---src-pages-business-setup-packages-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-infographics-js": () => import("./../../../src/pages/infographics.js" /* webpackChunkName: "component---src-pages-infographics-js" */),
  "component---src-pages-news-and-articles-js": () => import("./../../../src/pages/news-and-articles.js" /* webpackChunkName: "component---src-pages-news-and-articles-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-search-result-js": () => import("./../../../src/pages/search-result.js" /* webpackChunkName: "component---src-pages-search-result-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-thank-you-request-a-callback-js": () => import("./../../../src/pages/thank-you-request-a-callback.js" /* webpackChunkName: "component---src-pages-thank-you-request-a-callback-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-business-service-landing-template-js": () => import("./../../../src/templates/businessServiceLandingTemplate.js" /* webpackChunkName: "component---src-templates-business-service-landing-template-js" */),
  "component---src-templates-business-service-template-js": () => import("./../../../src/templates/businessServiceTemplate.js" /* webpackChunkName: "component---src-templates-business-service-template-js" */),
  "component---src-templates-business-setup-template-js": () => import("./../../../src/templates/businessSetupTemplate.js" /* webpackChunkName: "component---src-templates-business-setup-template-js" */),
  "component---src-templates-infographic-template-js": () => import("./../../../src/templates/infographicTemplate.js" /* webpackChunkName: "component---src-templates-infographic-template-js" */),
  "component---src-templates-license-landing-template-js": () => import("./../../../src/templates/licenseLandingTemplate.js" /* webpackChunkName: "component---src-templates-license-landing-template-js" */),
  "component---src-templates-news-article-template-js": () => import("./../../../src/templates/newsArticleTemplate.js" /* webpackChunkName: "component---src-templates-news-article-template-js" */),
  "component---src-templates-ppc-landing-template-js": () => import("./../../../src/templates/ppcLandingTemplate.js" /* webpackChunkName: "component---src-templates-ppc-landing-template-js" */),
  "component---src-templates-step-landing-template-js": () => import("./../../../src/templates/stepLandingTemplate.js" /* webpackChunkName: "component---src-templates-step-landing-template-js" */)
}

